<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="false"
  >
    <v-text-field
      v-model="input.hotlineNumber"
      v-mask="'7##########'"
      label="Телефон"
      :rules="[$validate.required, $validate.phoneMin2, $validate.phoneMax2]"
    >
    </v-text-field>
    <v-select
      v-model="input.departmentId"
      label="Филиал"
      :items="USER.userInfo.departments"
      item-value="id"
      item-text="name"
      :rules="[$validate.required]"
    ></v-select>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: false,
      mode: "Добавить",
      input: {},
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
      PHONE: "Phone/STATE",
    }),
  },
  methods: {
    ...mapActions({
      addHotlineNumber: "Phone/ADD_HOTLINE",
    }),
    async addButton() {
      this.loading = true;
      let response = await this.addHotlineNumber(this.input);
      if (response.type === "success") {
        this.$router.go(-1);
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.text,
        });
      }
      this.loading = false;
    },
  },
};
</script>
